import { ComponentProps, useCallback, useState } from 'react'
import { InvestmentOpportunity } from 'api/cms'
import { axios } from 'api/lib'
import { cn } from 'ui/lib'
import { analytics } from 'ui/lib/analytics'
import { ActionButton } from '../ActionButton'
import { Dialog } from '../Dialog'

interface IoiWithdrawConfirmationDialogProps
  extends Omit<ComponentProps<typeof Dialog>, 'actions' | 'onSubmit'> {
  readonly investment: InvestmentOpportunity
  readonly onWithdraw: () => void
  readonly onClose: () => void
}

export function IoiWithdrawConfirmationDialog({
  className,
  investment,
  onWithdraw,
  onClose,
  ...props
}: IoiWithdrawConfirmationDialogProps) {
  const [isLoading, setIsLoading] = useState(false)

  const onWithdrawHandlerHandler = useCallback(async () => {
    setIsLoading(true)
    try {
      await axios.delete(`/api/ioi?investmentOpportunityId=${investment.id}`)
      analytics?.track('IOI withdraw', {
        action: 'IOI withdraw',
        investmentId: investment.id,
        fundName: investment.title,
      })
      onWithdraw()
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }, [investment.id, investment.title])

  const actions = (
    <>
      <ActionButton
        className="justify-center"
        disabled={isLoading}
        uiType="regular"
        onClick={onWithdrawHandlerHandler}
      >
        WITHDRAW
      </ActionButton>
      <ActionButton className="justify-center" disabled={isLoading} onClick={onClose}>
        CANCEL
      </ActionButton>
    </>
  )

  return (
    <Dialog actions={actions} onClose={onClose} {...props}>
      <Dialog.Title as="h3" className="mt-3 text-lg font-semibold leading-6 text-gray-900 sm:mt-5">
        Withdraw your Indication of Interest (“IOI”)?
      </Dialog.Title>
      <div className={cn('flex flex-col gap-4', className)} {...props}>
        Withdraw your IOI if you’re no longer interested in the opportunity. If you want to edit the
        amount or visibility of your IOI you can do so from the investment page. You will still be
        able to submit another IOI for this opportunity after you withdraw.
      </div>
    </Dialog>
  )
}
