import { useState } from 'react'
import GroupChannel from '@sendbird/uikit-react/GroupChannel'
import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader'
import GroupChannelList from '@sendbird/uikit-react/GroupChannelList'
import GroupChannelListHeader from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader'
import { cn } from 'ui/lib'

export function SendbirdChat() {
  const [channelUrl, setChannelUrl] = useState<string>()
  const [hideChannelList, setHideChannelList] = useState(false)

  const onChannelSelect = (channelUrl?: string) => {
    setChannelUrl(channelUrl)
    // hide list in case of channel selected and show in case of back button clicked or page unmounted
    setHideChannelList(!!channelUrl)
  }

  return (
    <div className={cn('grid flex-1', 'grid-cols-1', 'md:grid-cols-[auto,1fr]')}>
      {/* there is no way to customize FileViewer component
      temporary solution */}
      <style jsx global>{`
        .sendbird-fileviewer {
          padding-left: 64px;
        }
        @media screen and (max-width: 1024px) {
          .sendbird-fileviewer {
            padding-left: 0;
            padding-top: 64px;
          }
        }
      `}</style>
      <GroupChannelList
        className={cn(
          'h-auto w-full',
          { 'hidden md:block': hideChannelList },
          'col-start-1 col-end-1 row-start-1 row-end-1', // mobile-only
          'md:col-start-auto md:col-end-auto md:row-start-auto md:row-end-auto', // desktop-only
        )}
        onChannelSelect={(channel) => onChannelSelect(channel?.url)}
        onChannelCreated={() => {}}
        channelListQueryParams={{
          includeEmpty: true, // allows to see empty channels without any messages
        }}
        renderHeader={() => {
          // Hides create channel button in the top right corner.
          return <GroupChannelListHeader />
        }}
      />
      <div
        className={cn(
          'flex',
          { 'hidden md:block': !hideChannelList },
          'col-start-1 col-end-1 row-start-1 row-end-1', // mobile-only
          'md:col-start-auto md:col-end-auto md:row-start-auto md:row-end-auto', // desktop-only
        )}
      >
        <GroupChannel
          channelUrl={channelUrl}
          onBackClick={() => setHideChannelList(false)}
          renderChannelHeader={() => {
            // Hides button in the top right corner that allows banning and muting users.
            return <GroupChannelHeader renderRight={() => <></>} />
          }}
        />
      </div>
    </div>
  )
}
