import { ComponentProps, useEffect } from 'react'
import { User, UserSocialProfile } from 'api/dto'
import { useAxios } from 'api/lib'
import { format } from 'date-fns'
import { LDFlag, useFlag } from 'launchdarkly'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'
import { HasNominated } from './HasNominated'
import { NominatedBy } from './NominatedBy'
import { WelcomeLoading } from './WelcomeLoading'
import { FoundingMemberLabel, FoundingMemberLabelTheme } from '../FoundingMemberLabel'

const MEMBER_SINCE_FORMAT = 'MMMM yyyy'

interface WelcomeProps extends ComponentProps<'div'> {
  readonly user: User
  readonly isLoading?: boolean
  readonly onReady?: (flag: boolean) => void
}

export function Welcome({ className, user, isLoading, onReady, ...props }: WelcomeProps) {
  const enableMembersDiscoveryLink = useFlag<boolean>(LDFlag.EnableMemberDiscoveryNavigation)

  const { data: userSocialProfile, isLoading: isUserSocialProfileLoading } =
    useAxios<UserSocialProfile>(`/api/users/${user.id}/social-profile`)

  useEffect(() => {
    onReady?.(isUserSocialProfileLoading)
  }, [isUserSocialProfileLoading])

  const memberName = userSocialProfile?.firstName
  const isFoundingMember = userSocialProfile?.isFoundingMember
  const memberSince = user.joinedDate || user.createdAt
  const memberSinceFormatted = format(new Date(memberSince), MEMBER_SINCE_FORMAT)
  const nominatedBy = userSocialProfile?.nominatedBy || []
  const hasNominatedBy = nominatedBy.length > 0
  const hasNominated = userSocialProfile?.hasNominated || []

  if (isLoading || isUserSocialProfileLoading) {
    return <WelcomeLoading className={className} {...props} />
  }

  return (
    <div className={cn('flex flex-col gap-y-6 lg:gap-y-4', className)} {...props}>
      <div>
        <div className="flex flex-wrap-reverse gap-x-4 pb-1">
          <h1 className="text-deep-teal-900 text-nowrap text-2xl font-semibold">{`Welcome, ${memberName}`}</h1>
          {isFoundingMember && (
            <FoundingMemberLabel className="px-0" theme={FoundingMemberLabelTheme.light} />
          )}
        </div>
        {hasNominatedBy && <NominatedBy members={nominatedBy} />}
        <div className="flex items-center gap-x-2">
          <p className="text-deep-teal-500 text-nowrap text-xs font-medium leading-5">{`Member since ${memberSinceFormatted}`}</p>
          {enableMembersDiscoveryLink && (
            <>
              <span className="text-deep-teal-500 text-xs font-medium">|</span>
              <Link
                href="/member-directory"
                className="text-nowrap text-sm font-semibold leading-5 text-orange-600 hover:text-orange-800"
              >
                Discover Members
              </Link>
            </>
          )}
        </div>
      </div>
      <HasNominated className="lg:hidden" members={hasNominated} />
    </div>
  )
}
